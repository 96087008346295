"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CollectionAttribute = void 0;
var CollectionAttribute = /** @class */ (function () {
    function CollectionAttribute(trait_type, value) {
        this._trait_type = trait_type;
        this._value = value;
    }
    Object.defineProperty(CollectionAttribute.prototype, "trait_type", {
        get: function () {
            return this._trait_type;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CollectionAttribute.prototype, "value", {
        get: function () {
            return this._value;
        },
        enumerable: false,
        configurable: true
    });
    CollectionAttribute.prototype.equal = function (other) {
        return this._trait_type === other._trait_type && this._value === other._value;
    };
    return CollectionAttribute;
}());
exports.CollectionAttribute = CollectionAttribute;
