import * as React from "react";

import "./LoadingText.scss";
import {Grid} from "@mui/material";
import {FC} from "react";
import {LoadingTextInterface} from "./LoadingTextInterface";

export const LoadingText: FC<LoadingTextInterface> = ({
  loadingText,
  fixText,
}) => {
  

  return (
    <Grid container>
      <Grid>{fixText}</Grid>
      <div className="bouncing-loader">
        {loadingText}
        {!loadingText && <>
          <div/>
          <div/>
          <div/>
        </>
        }
      </div>
    </Grid>
  );
};
