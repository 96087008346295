import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import {CardActionArea, Grid, CircularProgress} from "@mui/material";

import "./CollectionItemCard.scss";
import {CardCollectionItemInterface} from "./CardCollectionItemInterface";
import {FC, useEffect, useState} from "react";

export const CollectionItemCard: FC<CardCollectionItemInterface> = ({
  collectionItem, 
  onClickCardFunction, 
  quacksToBeClaimed,
  collectionItemsSelected,
}) => {

  const [selected, setSelected] = useState<boolean>(false);

  const onClick = (): void => {
    if(onClickCardFunction(collectionItem)) {
      setSelected(!selected);
    }
  };

  useEffect(() => {
    setSelected(collectionItemsSelected.includes(collectionItem));
  }, [collectionItemsSelected]);

  const parseIPFSUrl = (cidUrl: string) => {
    return `https://ipfs.io/${cidUrl.replace("://", "/")}`;
  };

  const imageUrl = collectionItem.image.includes("ipfs") ?
    parseIPFSUrl(collectionItem.image) :
    collectionItem.image;

  const renderRewards = () => {
    if(quacksToBeClaimed === undefined) {
      // Return loader
      return <CircularProgress />;
    }
    return `$QUACKS ${quacksToBeClaimed}`;
  };

  return (
    <Card className={selected ? "card selected" : "card"} onClick={() => onClick()}>
      <CardActionArea>
        <CardMedia
          component="img"
          height="225"
          image={imageUrl}
        />
        <CardContent className={selected ? "card-background background-selected" : "card-background"}>
          <Grid className={"item-number"} xs={12} sm={12}>
            #{collectionItem.id}
          </Grid>
          <Grid className={"item-description"}>
            {renderRewards()}
          </Grid>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
