import {useEffect, useState} from "react";
import {QuacksContract} from "@staking/commons/services/contracts/quacks/QuacksContract";
import {EthersQuacks} from "@staking/commons/services/contracts/quacks/ethers/EthersQuacks";
import {useEthersSigner} from "./UseEthersSigner";

export function useQuacksContract() {
  const signer = useEthersSigner();
  const [quacksContract, setQuacksContract] = useState<QuacksContract>();

  useEffect(() => {
    if(!signer) return;

    EthersQuacks.getContractFromSigner(signer).then(quacksContract => {
      setQuacksContract(quacksContract);
    });

  }, [signer]);

  return {quacksContract};

}