import * as React from "react";
import {FC, useEffect, useState} from "react";
import {Grid, useMediaQuery} from "@mui/material";
import {CollectionSectionInterface} from "../interfaces/CollectionSectionInterface";

import "../styles/CollectionSection.scss";
import {CollectionItemDeck} from "../../../components/CollectionItemDeck/CollectionItemDeck";
import {CollectionName} from "@staking/commons/domain/Collection";
import {DuckCategoriesService} from "@staking/commons/services/categories/DuckCategoriesService";
import {collectionNameToDisplayText} from "./helpers/CollectionNameToDisplayText";
import {useTheme} from "@mui/material/styles";

export const CollectionSection: FC<CollectionSectionInterface> = ({
  collection,
  subCollections,
  callbackFunction,
  collectionItemsSelected,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [duckCategoryService , _] = useState<DuckCategoriesService>(DuckCategoriesService.createWithAllCollections());
  const [orderCategories , setOrderCategories] = useState<{rewards: number, name: string}[]>([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const collectionName = collection as CollectionName;
  
  useEffect(() => {
    const collectionNames = Object.keys(subCollections);
    const collectionsWithRewards = collectionNames.map((name) => {
      const rewards = duckCategoryService.getRewardsPerCategoryWithCollectionNameAndCategory(
        collectionName,
        name
      );
      return {rewards, name};
    });

    const orderCategoriesByRewards = collectionsWithRewards.sort((a, b) => {
      return b.rewards - a.rewards;
    });

    setOrderCategories(orderCategoriesByRewards);
  }, [subCollections]);

  if(Object.keys(subCollections).length === 0) {
    return;
  }
  
  function renderCategoryTitle(categoryWithRewards: {rewards: number, name: string}) {
    if(isMobile) {
      return `${categoryWithRewards.name} ($${categoryWithRewards.rewards})`;
    }
    return `${categoryWithRewards.name} ($QUACK: ${categoryWithRewards.rewards})`;
  }
    
  return(
    <Grid container className={"section"}>
      <Grid xs={12} sm={12} md={12} className={"section-title"}>
        {collectionNameToDisplayText(collectionName)}
      </Grid>
      <Grid container className={"container-card"}>
        {
          orderCategories.map((category) => (
            <>
              <Grid container className={"header"}>
                <Grid item xs={12} sm={6} md={6} lg={6} className={"subtype-title"}>
                  {renderCategoryTitle(category)}
                </Grid>
              </Grid>
              <Grid container className={"deck"}>
                <CollectionItemDeck 
                  items={subCollections[category.name]} 
                  onClickCardFunction={callbackFunction}
                  collectionItemsSelected={collectionItemsSelected}
                />
              </Grid>
            </>
          ))
        }
      </Grid>
    </Grid>
  );
};