"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AlchemyService = void 0;
var alchemy_sdk_1 = require("alchemy-sdk");
var constants_1 = require("../constants");
var AlchemyService = /** @class */ (function () {
    function AlchemyService(collections) {
        var _this = this;
        this.API_KEY = "WhV3ilYCz5P6TvBcAzwwRzWf4ZmczCgH";
        this.collectionNameToContractAddress = {};
        // Optional Config object, but defaults to demo api-key and eth-mainnet.
        var settings = {
            apiKey: this.API_KEY,
            network: alchemy_sdk_1.Network.ETH_MAINNET, // Replace with your network.
        };
        this.alchemy = new alchemy_sdk_1.Alchemy(settings);
        collections.forEach(function (collection) { return _this.collectionNameToContractAddress[collection.name] = collection.contractAddress; });
    }
    AlchemyService.createWithAllCollections = function () {
        return new AlchemyService(constants_1.SUPPORTED_COLLECTIONS);
    };
    AlchemyService.prototype.fetchAssetsPage = function (address, collectionNames, cursor) {
        return __awaiter(this, void 0, void 0, function () {
            var nftsForOwner, assets;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.alchemy.nft.getNftsForOwner(address, {
                            contractAddresses: collectionNames.map(function (name) { return _this.collectionNameToContractAddress[name]; }),
                            pageKey: cursor,
                            omitMetadata: true,
                            pageSize: 100
                        })];
                    case 1:
                        nftsForOwner = _a.sent();
                        assets = nftsForOwner.ownedNfts.map(function (nft) { return { token_id: nft.tokenId }; });
                        return [2 /*return*/, {
                                assets: assets,
                                next: nftsForOwner.pageKey
                            }];
                }
            });
        });
    };
    AlchemyService.prototype._fetchAssets = function (address, collectionNames) {
        return __awaiter(this, void 0, void 0, function () {
            var assets, cursor, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        assets = [];
                        cursor = null;
                        _a.label = 1;
                    case 1: return [4 /*yield*/, this.fetchAssetsPage(address, collectionNames, cursor)];
                    case 2:
                        response = _a.sent();
                        assets = assets.concat(response.assets);
                        cursor = response.next ? response.next : null;
                        _a.label = 3;
                    case 3:
                        if (cursor) return [3 /*break*/, 1];
                        _a.label = 4;
                    case 4: return [2 /*return*/, assets];
                }
            });
        });
    };
    AlchemyService.prototype.fetchAssets = function (address, collectionName) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this._fetchAssets(address, [collectionName])];
            });
        });
    };
    ;
    return AlchemyService;
}());
exports.AlchemyService = AlchemyService;
