import * as React from "react";

import "./HarvestButton.scss";

import {FC, useEffect, useState} from "react";
import {HarvestButtonInterface} from "./HarvestButtonInterface";
import {StakingApi} from "../../apis/StakingApi";
import {useConnectedAddress} from "../../hooks/ConnectedAddress";
import {ActionButton} from "../ActionButton/ActionButton";
import {useEthersSigner} from "../../hooks/UseEthersSigner";

// TODO: This logic should be in commons
const ONE_DAY = 24 * 60 * 60 * 1000; // ms

export const HarvestButton: FC<HarvestButtonInterface> = ({
  itemsToBeHarvest, 
  onHarvest,
  lastHarvested,
  harvestAmount
}) => {
  const {address} = useConnectedAddress();
  const signer = useEthersSigner();
  const [disableButton, setDisableButton] = useState<boolean>(true);
  const [tooltipText, setTooltipText] = useState<string>("Select ducks to claim rewards");
  const [notificationType, setNotificationType] = useState<string>();
  const [notificationMessage, setNotificationMessage] = useState<string>();
  const [displayNotification, setDisplayNotification] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  // Set if it's disable or not
  useEffect(() => {
    if(harvestAmount === 0 || !harvestAmount) {
      setTooltipText("You don't have anything to harvest");
      setDisableButton(true);
      return;
    }

    const timeDifference = Date.now() - lastHarvested;
    if(lastHarvested === undefined || (lastHarvested != 0 && timeDifference < ONE_DAY)) {
      const timeToGetToOneDay = ONE_DAY - timeDifference;
      const timeDifferenceInHours = parseFloat((timeToGetToOneDay / (60 * 60 * 1000)).toFixed(2));
      setTooltipText(`You can harvest once every 24 hours (remaining: ${timeDifferenceInHours})`);
      setDisableButton(true);
      return;
    }

    // Clean everything
    setDisableButton(false);
    setTooltipText("");
  }, [lastHarvested, harvestAmount]);


  async function onClick() {

    try {
      setLoading(true);
      // Sign message
      const timestamp = new Date().toUTCString();

      setNotificationType("info");
      setNotificationMessage("Please sign the message to harvest your tokens");
      setDisplayNotification(true);
      const signature = await signer.signMessage("I want to harvest my $QUACKS today " + timestamp);
      setDisplayNotification(false);

      // Wait for the api call
      const stakingApi = new StakingApi();
      await stakingApi.harvestRewards(address, signature, timestamp, itemsToBeHarvest.map(item => item.composedTokenId));

      // Wait for the async process of harvest to finish
      await stakingApi.waitForHarvest(address);

      setNotificationType("success");
      setNotificationMessage("$QUACKS were harvest successfully!");

      onHarvest();
    }
    catch(error: unknown){
      setNotificationType("error");
      setNotificationMessage("Something happened while trying to harvest your $QUACK");
    }
    finally {
      setLoading(false);
      setDisplayNotification(true);
    }
  }

  return (
    <ActionButton 
      disabledToolTipText={tooltipText} 
      disabled={disableButton}
      text={itemsToBeHarvest.length === 0 ? "Harvest" : `Harvest(${itemsToBeHarvest.length})`}
      notification={{
        alertType: notificationType, 
        message: notificationMessage, 
        display: displayNotification, 
        onClose: () => {setDisplayNotification(false);}
      }}
      onClick={() => onClick()}
      loading={loading}
    />
  );
};
