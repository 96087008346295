import {FC} from "react";

import * as React from "react";
import {Grid} from "@mui/material";

import "../styles/CollectionSection.scss";
import {CollectionSection} from "./CollectionSection";
import {DucksData} from "../../../hooks/DucksData";
import {CollectionItem} from "@staking/commons/dist/domain/CollectionItem";

export const DucksSection: FC<{
        ducksData: DucksData, 
        onCollectionItemSelected(collectionItem: CollectionItem): boolean,
        collectionItemsSelected: CollectionItem[],
    }> = ({ducksData, onCollectionItemSelected, collectionItemsSelected}) => {
    
      return(
        <Grid container className={"staking-collections"}>
          {
            Object.keys(ducksData).map((key) => (
              <CollectionSection 
                key={key} 
                collection={key} 
                subCollections={ducksData[key]}
                callbackFunction={onCollectionItemSelected}
                collectionItemsSelected={collectionItemsSelected}
              />
            ))
          }
        </Grid>
      );
    };