"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OGCategories = void 0;
// This enum is also order by category id used in the chain
var OGCategories;
(function (OGCategories) {
    OGCategories["DUCK"] = "DUCK";
    OGCategories["BACKGROUND_DUCK"] = "BACKGROUND DUCK";
    OGCategories["UPPER_CLASS"] = "UPPER CLASS";
    OGCategories["ASSEMBLED"] = "ASSEMBLED";
    OGCategories["DESERTER"] = "DESERTER";
    OGCategories["X_RAY"] = "X RAY";
    OGCategories["GREY_DUCK"] = "GREY DUCK";
    OGCategories["WISHES"] = "WISHES";
    OGCategories["ANGEL"] = "ANGEL";
    OGCategories["ZOMBIE"] = "ZOMBIE";
    OGCategories["ROYAL_ARMY"] = "ROYAL ARMY";
    OGCategories["BEDOUIN"] = "BEDOUIN";
    OGCategories["ONE_ONE"] = "1 - 1";
    OGCategories["ROYALTY"] = "ROYALTY"; // 13
})(OGCategories = exports.OGCategories || (exports.OGCategories = {}));
