import {useEffect, useState} from "react";
import {RawNFTIndexerFetcher} from "@staking/commons/services/fetcher/RawNFTIndexerFetcher";
import {DuckCategoriesService} from "@staking/commons/services/categories/DuckCategoriesService";
import {CollectionItem} from "@staking/commons/domain/CollectionItem";
import {useConnectedAddress} from "./ConnectedAddress";

export type DucksData = {[key in string]: { [key in string]: CollectionItem[] }}

export function useDucksData() {
  const { address } = useConnectedAddress();

  const [ducksData, setDucksData] = useState<DucksData>({});

  useEffect(() => {
    if(!address) {
      setDucksData({});
      return;
    }

    async function fetchDucksData() {
      const ducksData: DucksData = {};
      const collectionFetcher = RawNFTIndexerFetcher.createWithAllCollections();
      const categoryService = DuckCategoriesService.createWithAllCollections();

      const itemsByCategories = await collectionFetcher.getAllCollectionsItems(
        address,
      );

      itemsByCategories.forEach(itemsForCategory => {
        ducksData[itemsForCategory.collectionName] = categoryService.getCategoryForAll(itemsForCategory.collectionItems);
      });
      setDucksData(ducksData);
    }

    fetchDucksData();
  }, [address]);

  return {ducksData};
}