"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SUPPORTED_COLLECTIONS = void 0;
var Collection_1 = require("../../domain/Collection");
var HellDuckCategoriesService_1 = require("../categories/collections/HellDuckCategoriesService");
var SecondDuckCategoriesService_1 = require("../categories/collections/SecondDuckCategoriesService");
var OGDuckCategoriesService_1 = require("../categories/collections/OGDuckCategoriesService");
var UpsideDuckCategoriesService_1 = require("../categories/collections/UpsideDuckCategoriesService");
// TODO: Should all this information be here? Maybe separated?
exports.SUPPORTED_COLLECTIONS = [
    {
        name: Collection_1.CollectionName.OG,
        metadataUrl: "https://og.nonconformistducks.com/metadata/{id}",
        openSeaCollection: "nonconformistducks",
        contractAddress: "0x0f4b28d46cab209bc5fa987a92a26a5680538e45",
        categoryService: new OGDuckCategoriesService_1.OGDuckCategoriesService(),
        id: 1,
    },
    {
        name: Collection_1.CollectionName.Hell,
        metadataUrl: "https://hell.nonconformistducks.com/metadata/{id}",
        openSeaCollection: "nonconformisthellducks",
        contractAddress: "0x7b7dad77e4090160f9cb6ba57a5a774c12d4c28a",
        categoryService: new HellDuckCategoriesService_1.HellDuckCategoriesService(),
        id: 2,
    },
    {
        name: Collection_1.CollectionName.SecondGen,
        metadataUrl: "https://eggs.nonconformistducks.com/metadata/{id}",
        openSeaCollection: "noncoducks2gen",
        contractAddress: "0x488fb59d73669d5a1cdf0960214563756594c528",
        categoryService: new SecondDuckCategoriesService_1.SecondDuckCategoriesService(),
        id: 3,
    },
    {
        name: Collection_1.CollectionName.Upside,
        metadataUrl: "https://upside.nonconformistducks.com/metadata/{id}",
        openSeaCollection: "nonconformist-upside-ducks",
        contractAddress: "0xc6426937d6ef8db8ea49e3c0fda3bf0f24ebd561",
        categoryService: new UpsideDuckCategoriesService_1.UpsideDuckCategoriesService(),
        id: 4
    },
];
