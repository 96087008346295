"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddressReward = void 0;
var AddressReward = /** @class */ (function () {
    function AddressReward(lastHarvestTime, lastClaimTime, rewards) {
        this._lastHarvestTime = lastHarvestTime ? lastHarvestTime : 0;
        this._lastClaimTime = lastClaimTime ? lastClaimTime : 0;
        this._rewards = rewards ? rewards : 0;
    }
    Object.defineProperty(AddressReward.prototype, "lastHarvestTime", {
        get: function () {
            return this._lastHarvestTime;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AddressReward.prototype, "lastClaimTime", {
        get: function () {
            return this._lastClaimTime;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AddressReward.prototype, "rewards", {
        get: function () {
            return this._rewards;
        },
        enumerable: false,
        configurable: true
    });
    AddressReward.prototype.toJson = function () {
        return { rewards: this.rewards, lastHarvestTime: this.lastHarvestTime, lastClaimTime: this.lastClaimTime };
    };
    return AddressReward;
}());
exports.AddressReward = AddressReward;
