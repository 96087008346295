import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "@rainbow-me/rainbowkit/styles.css";
import {
  getDefaultWallets,
  RainbowKitProvider,
} from "@rainbow-me/rainbowkit";
import {
  configureChains,
  createConfig,
  WagmiConfig,
} from "wagmi";
import * as chain from "@wagmi/core/chains";
import { infuraProvider } from "wagmi/providers/infura";
import { publicProvider } from "wagmi/providers/public";

// Solve the buffer problem in webpack 5.0
// https://solana.stackexchange.com/questions/212/uncaught-referenceerror-buffer-is-not-defined-using-phantom-wallet-solana-and
import * as buffer from "buffer";
window.Buffer = buffer.Buffer;

const defaultChains = [];

if(process.env.REACT_APP_ENV != "production") {
  // Add hardhat first for the development environment
  defaultChains.push(chain.goerli);
  defaultChains.push(chain.hardhat);
}

defaultChains.push(chain.mainnet);

const { chains, publicClient } = configureChains(
  defaultChains,
  [
    infuraProvider({ apiKey: "86134dc8006341dc824d2fe36c43a948" }),
    publicProvider()
  ]
);

const { connectors } = getDefaultWallets({
  appName: "Nonconformist Ducks Staking",
  projectId: "16c0056136fee7edf8e64c3f11919c5e",
  chains
});

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <WagmiConfig config={wagmiConfig}>
      <RainbowKitProvider showRecentTransactions={true} chains={chains}>
        <App />
      </RainbowKitProvider>
    </WagmiConfig>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
