import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import { ConnectButton } from "@rainbow-me/rainbowkit";

import {MobileMenu} from "./MobileMenu";
import {MobileMenuItem} from "./MobileMenuItem";

import Logo from "../../assets/Logo.png";
import Instagram from "../../assets/Instagram.png";
import Twitter from "../../assets/Twitter.png";
import Discord from "../../assets/Discord.png";
import OpenSea from "../../assets/OpenSea.png";

import "./NavBar.scss";


const NONCO_PAGES = [
  {text: "ABOUT", link: "https://nonconformistducks.com/about"},
  {text: "UTILITY", link: "https://nonconformistducks.com/utility"}
];
const SOCIAL_MEDIA_PAGES = [
  {text: "Instagram", icon: Instagram, link: "https://www.instagram.com/noncoducks/"},
  {text: "Twitter", icon: Twitter, link: "https://twitter.com/noncoducks?s=20&t=zytj1wrdv-zxdLPQErZbRw"},
  {text: "Discord", icon: Discord, link: "https://discord.com/invite/noncoducks"},
  {text: "OpenSea", icon: OpenSea, link: "https://opensea.io/collection/nonconformistducks"},
];

function getMobileItems(): MobileMenuItem[] {
  return [...NONCO_PAGES, ...SOCIAL_MEDIA_PAGES];
}

function renderNoncoPages() {
  return (
    <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
      {NONCO_PAGES.map((page) => (
        <a
          key={page.text}
          style={{ margin: "2rem", color: "white", display: "block", textDecoration: "none" }}
          href={page.link}
          target="_blank" rel="noreferrer"
          className={"text"}
        >
          {page.text}
        </a>
      ))}
    </Box>
  );
}

function renderSocialMediaIcons() {
  return (
    <Box sx={{ flexGrow: 0, display: { xs: "none", md: "flex" }, paddingRight: "2rem"}}>
      {SOCIAL_MEDIA_PAGES.map((page) => (
        <a key={page.text} href={page.link} target="_blank" rel="noreferrer">
          <img
            src={page.icon}
            alt={page.text}
            style={{maxHeight: "1.5rem", padding: "0.5rem"}}
          />
        </a>
      ))}
    </Box>
  );
}

function ResponsiveAppBar() {

  return (
    <AppBar position="static" style={{backgroundColor: "black"}}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <MobileMenu items={getMobileItems()} />
          <Box sx={{ flexGrow: 2, display: { xs: "flex" }, justifyContent: { xs: "center" } }}>
            <img src={Logo} alt="Nonco Ducks Logo" style={{maxHeight: "4rem", margin: "auto"}} />
            {renderNoncoPages()}
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: "flex" }, justifyContent: "end"}}>
            {renderSocialMediaIcons()}
            <ConnectButton
              chainStatus={{smallScreen: "none", largeScreen: "icon"}}
              showBalance={false}
              accountStatus={{smallScreen: "avatar", largeScreen: "full"}} />
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;