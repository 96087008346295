import * as React from "react";

import "./CollectionStat.scss";
import {CollectionStatInterface} from "./CollectionStatInterface";
import {Grid, useMediaQuery} from "@mui/material";
import {FC, useEffect, useState} from "react";
import {collectionNameToDisplayText} from "../../pages/Staking/sections/helpers/CollectionNameToDisplayText";
import {CollectionName} from "@staking/commons/domain/Collection";
import {useTheme} from "@mui/material/styles";

type DuckStat = {[key in string]: number}

export const CollectionStat: FC<CollectionStatInterface> = ({ducksData}) => {
  const [ducksStats, setDucksStats] = useState<DuckStat>({});
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if(!ducksData) return;

    const stats: DuckStat = {};

    Object.keys(ducksData).map((key) => {
      let ducksCounter = 0;

      Object.keys(ducksData[key]).map((subKey) => {
        ducksCounter += ducksData[key][subKey].length;
      });

      stats[key] = ducksCounter;
    });

    setDucksStats(stats);
  }, [ducksData]);

  return (
    <Grid item xs={12} className={"collection-stat"}>
      {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        Object.keys(ducksStats).map((key, index) => (
          <Grid key={key} container className={"stat-text"}>
            <Grid item xs={12} sm={12} md={12} className={"number-text"}>
              {ducksStats[key]}
            </Grid>
            <Grid item xs={12} sm={12} md={12} className={"description-text"}>
              {collectionNameToDisplayText(key as CollectionName, isMobile)}
            </Grid>
          </Grid>
        ))
      }
    </Grid>
  );
};
