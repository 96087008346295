"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Collection = exports.CollectionName = void 0;
var CollectionName;
(function (CollectionName) {
    CollectionName["OG"] = "OG";
    CollectionName["Hell"] = "Hell";
    CollectionName["SecondGen"] = "2 Gen";
    CollectionName["Upside"] = "Upside";
    CollectionName["Keys"] = "Keys"; // 4
})(CollectionName = exports.CollectionName || (exports.CollectionName = {}));
var Collection = /** @class */ (function () {
    function Collection(name) {
        this._name = name;
    }
    Object.defineProperty(Collection.prototype, "name", {
        get: function () {
            return this._name;
        },
        enumerable: false,
        configurable: true
    });
    Collection.getAllCollectionNames = function () {
        return Object.keys(CollectionName);
    };
    Collection.getCollectionId = function (collectionName) {
        return Object.values(CollectionName).indexOf(collectionName);
    };
    return Collection;
}());
exports.Collection = Collection;
