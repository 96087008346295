"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SecondCategories = void 0;
// This enum is also order by category id used in the chain
var SecondCategories;
(function (SecondCategories) {
    SecondCategories["DUCK"] = "DUCK";
    SecondCategories["HELL_DUCK"] = "HELL DUCK";
    SecondCategories["STOLEN_LETTER"] = "STOLEN LETTER";
    SecondCategories["POTION_DUCK"] = "POTION DUCK";
    SecondCategories["CHOCOLATE"] = "CHOCOLATE";
    SecondCategories["WARRIORS"] = "WARRIORS";
    SecondCategories["GOLDEN_DUCK"] = "GOLDEN DUCK";
})(SecondCategories = exports.SecondCategories || (exports.SecondCategories = {}));
