"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClaimedEventDTO = void 0;
var ClaimedEventDTO = /** @class */ (function () {
    function ClaimedEventDTO(claimer, claimAmount, expirationTime) {
        this._claimer = claimer;
        this._claimAmount = claimAmount;
        this._expirationTime = expirationTime;
    }
    Object.defineProperty(ClaimedEventDTO.prototype, "claimer", {
        get: function () {
            return this._claimer;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ClaimedEventDTO.prototype, "claimAmount", {
        get: function () {
            return this._claimAmount;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ClaimedEventDTO.prototype, "expirationTime", {
        get: function () {
            return this._expirationTime;
        },
        enumerable: false,
        configurable: true
    });
    return ClaimedEventDTO;
}());
exports.ClaimedEventDTO = ClaimedEventDTO;
