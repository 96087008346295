"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.HellDuckCategoriesService = void 0;
var AllCategoryFilter_1 = require("../filters/AllCategoryFilter");
var CollectionCategoryService_1 = require("./CollectionCategoryService");
var Collection_1 = require("../../../domain/Collection");
var SignleAttributeCategoryFilter_1 = require("../filters/SignleAttributeCategoryFilter");
var HellCategories_1 = require("../../../domain/categories/HellCategories");
var HellAttributes_1 = require("../../../domain/attributes/HellAttributes");
var DARK_ROYALTY_CATEGORY_FILTER = new SignleAttributeCategoryFilter_1.SingleAttributeCategoryFilter(HellCategories_1.HellCategories.DARK_ROYALTY, HellAttributes_1.HellAttributes.TYPE, ["Dark Royalty"]);
var GATE_KEEPER_CATEGORY_FILTER = new SignleAttributeCategoryFilter_1.SingleAttributeCategoryFilter(HellCategories_1.HellCategories.GATE_KEEPER, HellAttributes_1.HellAttributes.TYPE, ["Gatekeeper"]);
var ONE_ONE_CATEGORY_FILTER = new SignleAttributeCategoryFilter_1.SingleAttributeCategoryFilter(HellCategories_1.HellCategories.ONE_ONE, HellAttributes_1.HellAttributes.TYPE, ["1-1"]);
var SINNER_CATEGORY_FILTER = new SignleAttributeCategoryFilter_1.SingleAttributeCategoryFilter(HellCategories_1.HellCategories.SINNER, HellAttributes_1.HellAttributes.TYPE, ["Sinner"]);
var HORSE_DUCK_CATEGORY_FILTER = new SignleAttributeCategoryFilter_1.SingleAttributeCategoryFilter(HellCategories_1.HellCategories.HORSE_DUCK, HellAttributes_1.HellAttributes.TYPE, ["Horseduck"]);
var ASSEMBLED_CATEGORY_FILTER = new SignleAttributeCategoryFilter_1.SingleAttributeCategoryFilter(HellCategories_1.HellCategories.ASSEMBLED, HellAttributes_1.HellAttributes.TYPE, ["Assembled"]);
var DEMON_CATEGORY_FILTER = new SignleAttributeCategoryFilter_1.SingleAttributeCategoryFilter(HellCategories_1.HellCategories.DEMON, HellAttributes_1.HellAttributes.TYPE, ["Demon"]);
var UPPER_CLASS_FILTER = new SignleAttributeCategoryFilter_1.SingleAttributeCategoryFilter(HellCategories_1.HellCategories.UPPER_CLASS, HellAttributes_1.HellAttributes.TYPE, ["Upper Class"]);
var BACKGROUND_CATEGORY_FILTER = new SignleAttributeCategoryFilter_1.SingleAttributeCategoryFilter(HellCategories_1.HellCategories.BACKGROUND_DUCK, HellAttributes_1.HellAttributes.TYPE, ["Background Duck"]);
var DUCK_CATEGORY_FILTER = new AllCategoryFilter_1.AllCategoryFilter(HellCategories_1.HellCategories.DUCK);
var HELL_FILTERS = [
    DARK_ROYALTY_CATEGORY_FILTER,
    GATE_KEEPER_CATEGORY_FILTER,
    ONE_ONE_CATEGORY_FILTER,
    SINNER_CATEGORY_FILTER,
    HORSE_DUCK_CATEGORY_FILTER,
    ASSEMBLED_CATEGORY_FILTER,
    DEMON_CATEGORY_FILTER,
    UPPER_CLASS_FILTER,
    BACKGROUND_CATEGORY_FILTER,
    DUCK_CATEGORY_FILTER,
];
var HellDuckCategoriesService = /** @class */ (function (_super) {
    __extends(HellDuckCategoriesService, _super);
    function HellDuckCategoriesService() {
        return _super.call(this, Collection_1.CollectionName.Hell, HELL_FILTERS) || this;
    }
    HellDuckCategoriesService.prototype.mapCategoryNameToId = function (category) {
        // This method assumes that HellCategories is an enum and that it's order by id
        return Object.values(HellCategories_1.HellCategories).indexOf(category);
    };
    return HellDuckCategoriesService;
}(CollectionCategoryService_1.CollectionCategoryService));
exports.HellDuckCategoriesService = HellDuckCategoriesService;
