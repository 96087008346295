import * as React from "react";
import {Grid} from "@mui/material";

import "./CollectionItemDeck.scss";
import {CollectionItemDeckInterface} from "./CollectionItemDeckInterface";
import {CollectionItemCard} from "../CollectionItemCard/CollectionItemCard";
import {CollectionItem} from "@staking/commons/dist/domain/CollectionItem";
import {FC} from "react";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const CollectionItemDeck: FC<CollectionItemDeckInterface> = ({
  items, 
  onClickCardFunction, 
  collectionItemsSelected,
}) => {

  return (
    <Grid container spacing={2} direction="row" alignItems="flex-start">
      {items.map((elem: CollectionItem) => (
        <Grid item xs={12} sm={6} md={4} lg={3} key={elem.id} className={"responsive-deck"}>
          <CollectionItemCard 
            collectionItem={elem} 
            onClickCardFunction={onClickCardFunction} 
            quacksToBeClaimed={elem.reward}
            collectionItemsSelected={collectionItemsSelected}
          />
        </Grid>
      ))}
    </Grid>
  );
};
