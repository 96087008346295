import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import {Staking} from "./pages/Staking/Staking";
import {EmptyStateText} from "./components/EmptyStateText/EmptyStateText";


const App = () => {

  return (
    <Router>
      <Routes>
        <Route path="" element={<Staking />}/>
        <Route path="/text" element={<EmptyStateText message={"A ver gaston"}/>}/>
      </Routes>
    </Router>
  );
};

export default App;
