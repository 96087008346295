import {ClaimRewardsParam} from "@staking/commons/services/contracts/staking/ClaimRewardsParams";
import {AddressReward} from "@staking/commons/domain/AddressReward";

export class StakingApi {
  private readonly BASE_URL;

  constructor() {
    if(process.env.REACT_APP_ENV != "production") {
      this.BASE_URL = "https://hfm1goi9ff.execute-api.us-east-2.amazonaws.com/prod";
    } else {
      this.BASE_URL = "https://6o1x6m1jy3.execute-api.us-east-2.amazonaws.com/prod" ;
    }
  }

  async claimRewards(address: string, signature: string, timestamp: string): Promise<ClaimRewardsParam> {
    const response = await fetch(
      this.BASE_URL + "/claim", {
        method: "POST",
        body: JSON.stringify({address, signature, timestamp})
      }
    );
    const claimRewardsParam = await response.json();
    return claimRewardsParam.claimRewardParam;
  }

  async getTokenRewards(tokenIds: string[], collectionName: string, cache = true): Promise<number[]> {
    const options: RequestInit = {};

    if(!cache) {
      options["cache"] = "no-cache";
    }
    const response = await fetch(
      this.BASE_URL + `/token-rewards?tokenIds=${tokenIds.join(",")}&collectionName=${collectionName}`, options
    );
    const jsonResponse = await response.json();
    return jsonResponse.rewards;
  }

  async getAddressRewards(address: string, cache = true): Promise<AddressReward> {
    const options: RequestInit = {};

    if(!cache) {
      options["cache"] = "no-cache";
    }
    const response = await fetch(
      this.BASE_URL + `/address-rewards?address=${address}`, options
    );
    const {rewards, lastHarvestTime, lastClaimTime} = await response.json();
    return new AddressReward(lastHarvestTime, lastClaimTime, rewards);
  }

  async harvestRewards(address: string, signature: string, timestamp: string, composedTokenIds: string[]): Promise<void> {
    const response = await fetch(
      this.BASE_URL + "/harvest", {
        method: "POST",
        body: JSON.stringify({address, signature, timestamp, composedTokenIds})
      }
    );
    if(response.status >= 300) {
      throw Error("Response error");
    }
    return;
  }

  async waitForHarvest(address: string): Promise<void> {
    let isHarvest = false;
    while (!isHarvest) {
      await new Promise(resolve => {
        setTimeout(resolve, 5000);
      });
      const rewards = await this.getAddressRewards(address, false);

      const FIVE_MINUTES = 5 * 60 * 1000; // milliseconds
      const lastHarvestTime = rewards.lastHarvestTime;
      if(lastHarvestTime && lastHarvestTime != 0 && (Date.now() - lastHarvestTime) < FIVE_MINUTES) {
        isHarvest = true;
      }
    }
    return;
  }
}
