"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.SecondDuckCategoriesService = void 0;
var AllCategoryFilter_1 = require("../filters/AllCategoryFilter");
var CollectionCategoryService_1 = require("./CollectionCategoryService");
var Collection_1 = require("../../../domain/Collection");
var SignleAttributeCategoryFilter_1 = require("../filters/SignleAttributeCategoryFilter");
var SecondAttributes_1 = require("../../../domain/attributes/SecondAttributes");
var SecondCategories_1 = require("../../../domain/categories/SecondCategories");
var GOLDEN_CATEGORY_FILTER = new SignleAttributeCategoryFilter_1.SingleAttributeCategoryFilter(SecondCategories_1.SecondCategories.GOLDEN_DUCK, SecondAttributes_1.SecondAttributes.TYPE, ["Golden"]);
var WARRIORS_CATEGORY_FILTER = new SignleAttributeCategoryFilter_1.SingleAttributeCategoryFilter(SecondCategories_1.SecondCategories.WARRIORS, SecondAttributes_1.SecondAttributes.TYPE, ["Warrior"]);
var CHOCOLATE_CATEGORY_FILTER = new SignleAttributeCategoryFilter_1.SingleAttributeCategoryFilter(SecondCategories_1.SecondCategories.CHOCOLATE, SecondAttributes_1.SecondAttributes.TYPE, ["Chocolate"]);
var POTION_CATEGORY_FILTER = new SignleAttributeCategoryFilter_1.SingleAttributeCategoryFilter(SecondCategories_1.SecondCategories.POTION_DUCK, SecondAttributes_1.SecondAttributes.TYPE, ["Potion"]);
var STOLEN_LETTER_CATEGORY_FILTER = new SignleAttributeCategoryFilter_1.SingleAttributeCategoryFilter(SecondCategories_1.SecondCategories.STOLEN_LETTER, SecondAttributes_1.SecondAttributes.TYPE, ["Stolen Letters"]);
var HELL_CATEGORY_FILTER = new SignleAttributeCategoryFilter_1.SingleAttributeCategoryFilter(SecondCategories_1.SecondCategories.HELL_DUCK, SecondAttributes_1.SecondAttributes.TYPE, ["Hell"]);
var DUCK_CATEGORY_FILTER = new AllCategoryFilter_1.AllCategoryFilter(SecondCategories_1.SecondCategories.DUCK);
var SECOND_FILTERS = [
    GOLDEN_CATEGORY_FILTER,
    WARRIORS_CATEGORY_FILTER,
    CHOCOLATE_CATEGORY_FILTER,
    POTION_CATEGORY_FILTER,
    STOLEN_LETTER_CATEGORY_FILTER,
    HELL_CATEGORY_FILTER,
    DUCK_CATEGORY_FILTER,
];
var SecondDuckCategoriesService = /** @class */ (function (_super) {
    __extends(SecondDuckCategoriesService, _super);
    function SecondDuckCategoriesService() {
        return _super.call(this, Collection_1.CollectionName.SecondGen, SECOND_FILTERS) || this;
    }
    SecondDuckCategoriesService.prototype.mapCategoryNameToId = function (category) {
        // This method assumes that SecondCategories is an enum and that it's order by id
        return Object.values(SecondCategories_1.SecondCategories).indexOf(category);
    };
    return SecondDuckCategoriesService;
}(CollectionCategoryService_1.CollectionCategoryService));
exports.SecondDuckCategoriesService = SecondDuckCategoriesService;
