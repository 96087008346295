import {FC} from "react";

import * as React from "react";
import {Grid, List, ListItem} from "@mui/material";

import "../styles/CollectionSection.scss";
import {EmptyStateText} from "../../../components/EmptyStateText/EmptyStateText";
import {SUPPORTED_COLLECTIONS} from "@staking/commons/services/fetcher/constants";


export const NoDucksSection: FC = () => {
    
  return(
    <Grid container className={"empty-state"}>
      <Grid item>
        <EmptyStateText message={"You don't have any ducks. Try buying them at Opensea:"}/>
        <List className={"empty-state"}>
          {SUPPORTED_COLLECTIONS.map(collectionInfo => {
            return <ListItem key={collectionInfo.name} style={{justifyContent: "center"}}>
              <a 
                className={"empty-state-text"} 
                href={`https://opensea.io/collection/${collectionInfo.openSeaCollection}`}
                target="_blank" rel="noreferrer"
              >
                {collectionInfo.name} collection
              </a>
            </ListItem>;
          })}
        </List>
      </Grid>
    </Grid>
  );
};