import React, {useEffect, useState} from "react";
import NavBar from "../../components/NavBar/NavBar";
import {Grid} from "@mui/material";

import "./styles/Stacking.scss";
import {CollectionStat} from "../../components/CollectionStat/CollectionStat";
import {TokenStat} from "../../components/TokenStat/TokenStat";
import {DucksData, useDucksData} from "../../hooks/DucksData";
import {useTokenStatsInfo} from "../../hooks/TokenStatsInfo";
import {CollectionItem} from "@staking/commons/domain/CollectionItem";
import {NoAddressSection} from "./sections/NoAddressSection";
import {NoDucksSection} from "./sections/NoDucksSection";
import {DucksSection} from "./sections/DucksSection";
import {useConnectedAddress} from "../../hooks/ConnectedAddress";

function renderDucksSection(
  hasAnyDuck: boolean,
  loading: boolean,
  ducksData: DucksData,
  collectionItemsSelected: CollectionItem[],
  onCollectionItemSelected: (collectionItem: CollectionItem) => boolean,
) {
  if(loading){
    return <></>;
  }
  else if (!hasAnyDuck) {
    return <NoDucksSection/>;
  }
  return <DucksSection 
    ducksData={ducksData}
    collectionItemsSelected={collectionItemsSelected}
    onCollectionItemSelected={onCollectionItemSelected}
  />;
}

export function Staking() {
  const [collectionItemsSelected, setCollectionItemsSelected] = useState<CollectionItem[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [harvest, setHarvest] = useState<boolean>(false); // Would change when Quacks were claimed
  const [hasAnyDuck, setHasAnyDuck] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  const { address } = useConnectedAddress();


  const {ducksData} = useDucksData();
  const {tokenStatInfo} = useTokenStatsInfo(harvest, ducksData);

  useEffect(() => {
    if(harvest) {
      // Reset everything when it has been harvest
      setCollectionItemsSelected([]);
      setHarvest(false);
    }
  }, [harvest]);
  
  useEffect(() => {
    Object.keys(ducksData).map(collection => {
      Object.keys(ducksData[collection]).map(category => {
        if(ducksData[collection][category].length != 0){
          setHasAnyDuck(true);
          setLoading(false);
          return;
        }
      });
      setLoading(false);
      return;
    });
    return;
  }, [ducksData]);



  const onCollectionItemSelected = (collectionItem: CollectionItem): boolean  => {
    if(!collectionItemsSelected.includes(collectionItem)){
      setCollectionItemsSelected([...collectionItemsSelected, collectionItem]);
    }
    else {
      setCollectionItemsSelected(
        collectionItemsSelected.filter(collectionItemSelected => collectionItemSelected != collectionItem)
      );
    }
    return true;
  };

  return (
    <Grid container className={"staking-section"}>
      <NavBar />
      { address &&
          <>
            <Grid container className={"stat-section"}>
              <Grid item xs={12} sm={6} md={6} lg={6} className={"collection-stat-section"}>
                <CollectionStat ducksData={ducksData}/>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} className={"token-stat-section"}>
                <TokenStat 
                  harvest={harvest} 
                  onHarvest={()=> {setHarvest(true);}} 
                  itemsToBeHarvest={collectionItemsSelected}
                  tokenStatInfo={tokenStatInfo}
                />
              </Grid>
            </Grid>
            <Grid container className={"button-section"}>
              <Grid item xs={0} sm={6} md={6} lg={6} />
              <Grid item xs={12} sm={6} md={6} lg={6} className={"item-button"}>
                {/*We will hide the claim button until the functionality is done*/}
                {/*<ClaimButton collectionItemsSelected={collectionItemsSelected} callbackFunction={() => setClaim(true)} />*/}
              </Grid>
            </Grid>
            {renderDucksSection(hasAnyDuck, loading, ducksData, collectionItemsSelected, onCollectionItemSelected)}
          </>
      }
      {!address && <NoAddressSection />}
    </Grid>
  );
}