import {FC} from "react";

import * as React from "react";
import {Grid} from "@mui/material";

import {EmptyStateText} from "../../../components/EmptyStateText/EmptyStateText";

export const NoAddressSection: FC = () => {

  return(
    <Grid container className={"empty-state"}>
      <Grid item>
        <EmptyStateText message={"Please connect your wallet to continue"}/>
      </Grid>
    </Grid>
  );
};