import * as React from "react";
import {Button, CircularProgress, Grid, Tooltip} from "@mui/material";

import "./ActionButton.scss";

import {FC} from "react";
import {ActionButtonInterface} from "./ActionButtonInterface";
import {Notification} from "../Notification/Notification";

export const ActionButton: FC<ActionButtonInterface> = (actionButtonInterface) => {
  const {
    disabledToolTipText,
    disabled,
    text,
    notification,
    onClick,
    loading
  } = actionButtonInterface;

  function renderButton() {
    if(loading) {
      return <CircularProgress />;
    }
    return <Button
      disabled={disabled}
      variant="contained"
      color="warning"
      onClick={() => onClick()}
      className={"action-button"}
    >
      {text}
    </Button>;
  }

  return (
    <Grid container>
      <Grid container>
        <Tooltip title={disabledToolTipText} arrow>
          <span>
            {renderButton()}
          </span>
        </Tooltip>
      </Grid>
      <Notification
        alertType={notification.alertType}
        message={notification.message}
        display={notification.display}
        onClose={() => notification.onClose()}
      />
    </Grid>
  );
};
