"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpsideDuckCategoriesService = void 0;
var AllCategoryFilter_1 = require("../filters/AllCategoryFilter");
var CollectionCategoryService_1 = require("./CollectionCategoryService");
var Collection_1 = require("../../../domain/Collection");
var SignleAttributeCategoryFilter_1 = require("../filters/SignleAttributeCategoryFilter");
var UpsideAttributes_1 = require("../../../domain/attributes/UpsideAttributes");
var UpsideCategories_1 = require("../../../domain/categories/UpsideCategories");
var CLASS_A_FILTER = new SignleAttributeCategoryFilter_1.SingleAttributeCategoryFilter(UpsideCategories_1.UpsideCategories.A, UpsideAttributes_1.UpsideAttributes.TYPE, ["A"]);
var CLASS_B_FILTER = new SignleAttributeCategoryFilter_1.SingleAttributeCategoryFilter(UpsideCategories_1.UpsideCategories.B, UpsideAttributes_1.UpsideAttributes.TYPE, ["B"]);
var CLASS_C_FILTER = new AllCategoryFilter_1.AllCategoryFilter(UpsideCategories_1.UpsideCategories.C);
var UPSIDE_FILTERS = [
    CLASS_A_FILTER,
    CLASS_B_FILTER,
    CLASS_C_FILTER
];
var UpsideDuckCategoriesService = /** @class */ (function (_super) {
    __extends(UpsideDuckCategoriesService, _super);
    function UpsideDuckCategoriesService() {
        return _super.call(this, Collection_1.CollectionName.Upside, UPSIDE_FILTERS) || this;
    }
    UpsideDuckCategoriesService.prototype.mapCategoryNameToId = function (category) {
        // This method assumes that UpsideCategories is an enum and that it's order by id
        return Object.values(UpsideCategories_1.UpsideCategories).indexOf(category);
    };
    return UpsideDuckCategoriesService;
}(CollectionCategoryService_1.CollectionCategoryService));
exports.UpsideDuckCategoriesService = UpsideDuckCategoriesService;
