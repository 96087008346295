"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CollectionItem = void 0;
var CollectionItem = /** @class */ (function () {
    function CollectionItem(collection, id, name, description, image, attributes, blockchainId) {
        this._collection = collection;
        this._id = id.replace("#", "");
        this._blockchainId = parseInt(blockchainId);
        this._name = name;
        this._description = description;
        this._image = image;
        this._attributes = attributes;
    }
    Object.defineProperty(CollectionItem.prototype, "collection", {
        get: function () {
            return this._collection;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CollectionItem.prototype, "id", {
        get: function () {
            return this._id;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CollectionItem.prototype, "name", {
        get: function () {
            return this._name;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CollectionItem.prototype, "description", {
        get: function () {
            return this._description;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CollectionItem.prototype, "image", {
        get: function () {
            return this._image;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CollectionItem.prototype, "blockchainId", {
        get: function () {
            return this._blockchainId;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CollectionItem.prototype, "composedTokenId", {
        get: function () {
            return "".concat(this.collection.name, ":").concat(this.blockchainId);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CollectionItem.prototype, "reward", {
        get: function () {
            return this._reward;
        },
        set: function (value) {
            this._reward = value;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CollectionItem.prototype, "attributes", {
        get: function () {
            return this._attributes;
        },
        set: function (value) {
            this._attributes = value;
        },
        enumerable: false,
        configurable: true
    });
    CollectionItem.prototype.hasAttribute = function (otherAttribute) {
        return this._attributes.filter(function (myAttribute) { return myAttribute.equal(otherAttribute); }).length >= 1;
    };
    CollectionItem.prototype.getEqualAttributes = function (other) {
        return this._attributes.filter(function (myAttribute) { return other.hasAttribute(myAttribute); });
    };
    /*
    * Returns the collection attribute if it matches the attribute type and ANY of the attribute values
    * if attribute values is undefined it will not filter by value
    * */
    CollectionItem.prototype.getAttribute = function (attributeType, attributeValues) {
        if (attributeValues === void 0) { attributeValues = undefined; }
        return this._attributes.filter(function (attribute) {
            var hasAttribute = attribute.trait_type === attributeType;
            if (attributeValues) {
                hasAttribute =
                    hasAttribute && attributeValues.includes(attribute.value);
            }
            return hasAttribute;
        });
    };
    return CollectionItem;
}());
exports.CollectionItem = CollectionItem;
