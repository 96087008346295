import * as React from "react";

import "./ClaimButton.scss";

import {FC, useEffect, useState} from "react";
import {ClaimButtonInterface} from "./ClaimButtonInterface";
import {StakingApi} from "../../apis/StakingApi";
import {useStakingContract} from "../../hooks/StakingContract";
import {useAddRecentTransaction} from "@rainbow-me/rainbowkit";
import {useConnectedAddress} from "../../hooks/ConnectedAddress";
import {ActionButton} from "../ActionButton/ActionButton";
import {useEthersSigner} from "../../hooks/UseEthersSigner";

// TODO: This logic should be in commons
const ONE_DAY = 24 * 60 * 60 * 1000; // ms

export const ClaimButton: FC<ClaimButtonInterface> = ({quacksToBeClaim, lastClaimedTime, onClaim}) => {
  const signer = useEthersSigner();
  const {address} = useConnectedAddress();

  const [disableButton, setDisableButton] = useState<boolean>(true);
  const [tooltipText, setTooltipText] = useState<string>("Select ducks to claim rewards");
  const {stakingContract} = useStakingContract();
  const addRecentTransaction = useAddRecentTransaction();

  const [notificationType, setNotificationType] = useState<string>();
  const [notificationMessage, setNotificationMessage] = useState<string>();
  const [displayNotification, setDisplayNotification] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);

  // Set if it's disable or not
  useEffect(() => {

    if(!quacksToBeClaim || quacksToBeClaim === 0) {
      setTooltipText("You don't have anything to claim");
      setDisableButton(true);
      return;
    }

    const timeDifference = Date.now() - lastClaimedTime;
    if(lastClaimedTime === undefined || (lastClaimedTime != 0 && timeDifference < ONE_DAY)) {
      const timeToGetToOneDay = ONE_DAY - timeDifference;
      const timeDifferenceInHours = parseFloat((timeToGetToOneDay / (60 * 60 * 1000)).toFixed(2));
      setTooltipText(`You can claim once every 24 hours (remaining: ${timeDifferenceInHours})`);
      setDisableButton(true);
      return;
    }

    // Clean everything
    setDisableButton(false);
    setTooltipText("");
  }, [lastClaimedTime, quacksToBeClaim]);

  async function onClick() {
    setLoading(true);

    // Sign message
    const timestamp = new Date().toUTCString();

    setNotificationType("info");
    setNotificationMessage("Please sign the message to harvest your tokens");
    setDisplayNotification(true);
    const signature = await signer.signMessage("I want to claim my $QUACKS today " + timestamp);
    setDisplayNotification(false);

    const stakingApi = new StakingApi();
    const claimRewardsParam = await stakingApi.claimRewards(address, signature, timestamp);

    try {
      const tx = await stakingContract.claimRewards(
        claimRewardsParam
      );

      addRecentTransaction({
        hash: tx.tx_hash,
        description: "Claim Your Rewards",
        confirmations: 1,
      });
      setNotificationType("success");
      setNotificationMessage("$QUACKS were claimed successfully!");
      onClaim();
    }
    catch(error: unknown){
      console.log(error);
      setNotificationType("error");
      setNotificationMessage("Something happened while trying to claim $QUACK");
    }
    finally {
      setLoading(false);
      setDisplayNotification(true);
    }
  }

  return (
    <ActionButton
      disabledToolTipText={tooltipText}
      disabled={disableButton}
      text="Claim"
      notification={{
        alertType: notificationType,
        message: notificationMessage,
        display: displayNotification,
        onClose: () => {setDisplayNotification(false);}
      }}
      onClick={() => onClick()}
      loading={loading}
    />
  );
};
