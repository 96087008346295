"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RawToCollectionItemSerializer = void 0;
var CollectionItem_1 = require("@staking/commons/domain/CollectionItem");
var CollectionAttribute_1 = require("@staking/commons/domain/CollectionAttribute");
var RawToCollectionItemSerializer = /** @class */ (function () {
    function RawToCollectionItemSerializer() {
    }
    RawToCollectionItemSerializer.prototype.deserialize = function (blockchainId, rawCollectionItem, collection) {
        return new CollectionItem_1.CollectionItem(collection, rawCollectionItem.id, rawCollectionItem.name, rawCollectionItem.description, rawCollectionItem.image, rawCollectionItem.attributes.map(function (attribute) { return new CollectionAttribute_1.CollectionAttribute(attribute.trait_type, attribute.value); }), blockchainId);
    };
    return RawToCollectionItemSerializer;
}());
exports.RawToCollectionItemSerializer = RawToCollectionItemSerializer;
