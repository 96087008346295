import {useEffect, useState} from "react";
import {StakingContract} from "@staking/commons/services/contracts/staking/StakingContract";
import {EthersStaking} from "@staking/commons/services/contracts/staking/ethers/EthersStaking";
import {useEthersSigner} from "./UseEthersSigner";

export function useStakingContract() {
  const signer = useEthersSigner();
  const [stakingContract, setStakingContract] = useState<StakingContract>();

  useEffect(() => {
    if(!signer) return;

    EthersStaking.getContract(signer).then(stakingContract => {
      setStakingContract(stakingContract);
    });

  }, [signer]);

  return {stakingContract};

}