"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HellCategories = void 0;
// This enum is also order by category id used in the chain
var HellCategories;
(function (HellCategories) {
    HellCategories["DUCK"] = "DUCK";
    HellCategories["BACKGROUND_DUCK"] = "BACKGROUND DUCK";
    HellCategories["UPPER_CLASS"] = "UPPER CLASS";
    HellCategories["DEMON"] = "DEMON";
    HellCategories["ASSEMBLED"] = "ASSEMBLED";
    HellCategories["HORSE_DUCK"] = "HORSE DUCK";
    HellCategories["SINNER"] = "SINNER";
    HellCategories["ONE_ONE"] = "1-1";
    HellCategories["GATE_KEEPER"] = "GATE KEEPER";
    HellCategories["DARK_ROYALTY"] = "DARK ROYALTY"; // 9
})(HellCategories = exports.HellCategories || (exports.HellCategories = {}));
