"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CollectionCategoryService = void 0;
var CollectionCategoryService = /** @class */ (function () {
    function CollectionCategoryService(collectionName, filters) {
        this.filters = filters;
        this.collectionName = collectionName;
    }
    CollectionCategoryService.prototype.getCollection = function () {
        return this.collectionName;
    };
    /*
      This method returns the first category it founds of type T
      It's important that the categories are order descending by importance
      Example:
           1 - More important
           .
           N - Less importance
       */
    CollectionCategoryService.prototype.getCategory = function (collectionItem) {
        for (var i = 0; i < this.filters.length; i++) {
            var category = this.filters[i].isInCategory(collectionItem);
            if (category)
                return category;
        }
    };
    return CollectionCategoryService;
}());
exports.CollectionCategoryService = CollectionCategoryService;
