"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AllCategoryFilter = void 0;
var AllCategoryFilter = /** @class */ (function () {
    function AllCategoryFilter(category) {
        this.category = category;
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    AllCategoryFilter.prototype.isInCategory = function (collectionItem) {
        return this.category;
    };
    return AllCategoryFilter;
}());
exports.AllCategoryFilter = AllCategoryFilter;
