import * as React from "react";

import "./TokenStat.scss";
import {Grid} from "@mui/material";
import {FC, useEffect, useState} from "react";
import {TokenStatInterface} from "./TokenStatInterface";
import {useQuacksContract} from "../../hooks/QuacksContract";
import {StakingApi} from "../../apis/StakingApi";
import {HarvestButton} from "../Harvest/HarvestButton";
import {useConnectedAddress} from "../../hooks/ConnectedAddress";
import {ClaimButton} from "../Claim/ClaimButton";
import {AddressReward} from "@staking/commons/domain/AddressReward";
import {LoadingText} from "../LoadingText/LoadingText";
import {useStakingContract} from "../../hooks/StakingContract";

export const TokenStat: FC<TokenStatInterface> = ({
  harvest,
  onHarvest,
  tokenStatInfo,
  itemsToBeHarvest
}) => {

  const [addressRewards, setAddressRewards] = useState<AddressReward | undefined>(undefined);
  const [quacksToBeClaim, setQuacksToBeClaim] = useState<number | undefined>(undefined);
  const [quackToBeHarvest, setQuackToBeHarvest] = useState<number | undefined>(undefined);
  const [quacksAddressBalance, setQuacksAddressBalance] = useState<number | undefined>(undefined);
  const [oldQuacksAddressBalance, setOldQuacksAddressBalance] = useState<number | undefined>(undefined);
  const [claimingTime, setClaimingTime] = useState<number | undefined>(undefined);

  const { address } = useConnectedAddress();
  const {quacksContract} = useQuacksContract();
  const {stakingContract} = useStakingContract();

  const onClaim = () => {
    setQuacksToBeClaim(0);
    setQuacksAddressBalance(undefined);
    const intervalId = setInterval(async () => {
      const balance = await quacksContract.balanceOf(address);
      if(balance != oldQuacksAddressBalance) {
        setQuacksAddressBalance(balance);
        clearInterval(intervalId);
      }
    }, 5000);
  };

  useEffect(() => {
    if(!quacksContract || !address) return;

    quacksContract.balanceOf(
      address
    ).then(balance => {
      setQuacksAddressBalance(balance);
      setOldQuacksAddressBalance(balance);
    });

  }, [quacksContract, address]);

  useEffect(() => {
    if(!stakingContract || !address) return;

    stakingContract.getClaimTime(
      address
    ).then(time => setClaimingTime(time * 1000));

  }, [quacksContract, address]);

  useEffect(() => {
    if(!claimingTime) return;

    // Give time for the cron to process the transaction
    const FIVE_MINUTES = 5 * 60 * 1000; // milliseconds
    if(claimingTime && claimingTime != 0 && (Date.now() - claimingTime) < FIVE_MINUTES) {
      setQuacksToBeClaim(0);
    }

  }, [claimingTime]);

  useEffect(() => {
    if(!tokenStatInfo) return;

    let totalQuacksReward = 0;

    if(itemsToBeHarvest.length > 0) {
      itemsToBeHarvest.forEach(collectionItem => {
        totalQuacksReward += tokenStatInfo[collectionItem.id].reward;
      });
      setQuackToBeHarvest(totalQuacksReward);
    }
    else if(Object.keys(tokenStatInfo).length > 0) {
      totalQuacksReward = Object.keys(tokenStatInfo).reduce(
        (total: number, infoKey: string) => {return total + tokenStatInfo[infoKey].reward;},
        0
      );
      setQuackToBeHarvest(totalQuacksReward);
    }

    // Run the first time and every time tokensSelected changes
  }, [itemsToBeHarvest, tokenStatInfo]);

  useEffect(() => {
    if(!address) return;

    const stakingApi = new StakingApi();
    // If harvest is true then reload without the cache, it means that the user harvested his tokens,
    // and we want to show the updated results
    const cache = !harvest;
    stakingApi.getAddressRewards(address, cache).then((addressReward) => {
      setAddressRewards(addressReward);
      setQuacksToBeClaim(addressReward.rewards);
    });

  }, [address, harvest]);

  return (
    <Grid container className={"token-stat"}>
      <Grid container columns={2} className={"total-quack-to-be-claimed"}>
        <Grid>
          <LoadingText fixText={"$QUACK TO BE HARVEST:"} loadingText={quackToBeHarvest?.toString()} />
        </Grid>
        <Grid>
          <HarvestButton 
            itemsToBeHarvest={itemsToBeHarvest} 
            onHarvest={onHarvest}
            lastHarvested={addressRewards?.lastHarvestTime}
            harvestAmount={quackToBeHarvest}
          />
        </Grid>
      </Grid>
      <Grid container columns={2} className={"total-quack-to-be-claimed"}>
        <Grid>
          <LoadingText fixText={"$QUACK TO BE CLAIM:"} loadingText={quacksToBeClaim?.toString()} />
        </Grid>
        <Grid>
          <ClaimButton 
            lastClaimedTime={claimingTime}
            quacksToBeClaim={quacksToBeClaim}
            onClaim={onClaim}
          />
        </Grid>
      </Grid>
      <Grid container columns={2} className={"total-quack-to-be-claimed"}>
        <Grid>
          <LoadingText fixText={"$QUACK BALANCE:"} loadingText={quacksAddressBalance?.toString()} />
        </Grid>
      </Grid>
    </Grid>
  );
};
