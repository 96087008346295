"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DuckCategoriesService = void 0;
var Collection_1 = require("../../domain/Collection");
var constants_1 = require("../fetcher/constants");
var REWARDS_PER_CATEGORY = {
    // OG
    0: {
        // DUCK -> 1-1s
        0: 5,
        1: 7,
        2: 15,
        3: 15,
        4: 15,
        5: 15,
        6: 15,
        7: 15,
        8: 15,
        9: 17,
        10: 20,
        11: 30,
        12: 50,
        13: 100,
    },
    // HELL
    1: {
        // DUCK -> 1-1s
        0: 5,
        1: 7,
        2: 15,
        3: 15,
        4: 15,
        5: 20,
        6: 25,
        7: 50,
        8: 80,
        9: 100,
    },
    // Second
    2: {
        // DUCK -> GOLDEN
        0: 8,
        1: 10,
        2: 15,
        3: 20,
        4: 25,
        5: 28,
        6: 30,
    },
    // UPSIDE
    3: {
        // C -> A
        0: 20,
        1: 30,
        2: 50,
    },
};
var DuckCategoriesService = /** @class */ (function () {
    function DuckCategoriesService(collectionsInfo) {
        var _this = this;
        this.categoryServices = {};
        collectionsInfo.map(function (collectionInfo) {
            _this.categoryServices[collectionInfo.categoryService.getCollection()] = collectionInfo.categoryService;
        });
    }
    DuckCategoriesService.createWithAllCollections = function () {
        return new DuckCategoriesService(constants_1.SUPPORTED_COLLECTIONS);
    };
    DuckCategoriesService.prototype.getCategory = function (collectionItem) {
        var categoryService = this.categoryServices[collectionItem.collection.name];
        if (!categoryService)
            return;
        return categoryService.getCategory(collectionItem);
    };
    DuckCategoriesService.prototype.getCategoryId = function (collectionName, category) {
        var categoryService = this.categoryServices[collectionName];
        if (!categoryService)
            return;
        return categoryService.mapCategoryNameToId(category);
    };
    DuckCategoriesService.prototype.getCategoryForAll = function (collectionItems) {
        var _this = this;
        var categories = {};
        collectionItems.forEach(function (collectionItem) {
            var category = _this.getCategory(collectionItem);
            if (!category)
                return;
            if (!categories[category]) {
                categories[category] = [];
            }
            categories[category].push(collectionItem);
        });
        return categories;
    };
    DuckCategoriesService.prototype.getRewardsPerCategory = function (collectionId, categoryId) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return REWARDS_PER_CATEGORY[collectionId][categoryId];
    };
    DuckCategoriesService.prototype.getRewardsPerCategoryWithCollectionItem = function (collectionItem) {
        var collectionName = collectionItem.collection.name;
        var category = this.getCategory(collectionItem);
        if (!category) {
            throw Error("Couldn't find category for collection item: (".concat(collectionItem.id, ", ").concat(collectionName, ")"));
        }
        return this.getRewardsPerCategoryWithCollectionNameAndCategory(collectionName, category);
    };
    DuckCategoriesService.prototype.getRewardsPerCategoryWithCollectionNameAndCategory = function (collectionName, category) {
        var collectionId = Collection_1.Collection.getCollectionId(collectionName);
        var categoryId = this.getCategoryId(collectionName, category);
        return this.getRewardsPerCategory(collectionId, categoryId);
    };
    return DuckCategoriesService;
}());
exports.DuckCategoriesService = DuckCategoriesService;
