import {CollectionName} from "@staking/commons/domain/Collection";

export function collectionNameToDisplayText(collectionName: CollectionName, displaySmall = false): string {

  switch (collectionName) {
  case CollectionName.OG:
    return displaySmall ? "OG" : "OG COLLECTION";
  case CollectionName.Hell:
    return displaySmall ? "HELL" : "HELL COLLECTION";
  case CollectionName.SecondGen:
    return displaySmall ? "2nd GEN" : "2nd GEN DUCKS";
  case CollectionName.Upside:
    return displaySmall ? "UPSIDE" : "UPSIDE DUCKS";
  }
}