"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpsideCategories = void 0;
// This enum is also order by category id used in the chain
var UpsideCategories;
(function (UpsideCategories) {
    UpsideCategories["C"] = "Class C";
    UpsideCategories["B"] = "Class B";
    UpsideCategories["A"] = "Class A"; // 2
})(UpsideCategories = exports.UpsideCategories || (exports.UpsideCategories = {}));
