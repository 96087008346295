"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.OGDuckCategoriesService = void 0;
var AllCategoryFilter_1 = require("../filters/AllCategoryFilter");
var CollectionCategoryService_1 = require("./CollectionCategoryService");
var Collection_1 = require("../../../domain/Collection");
var SignleAttributeCategoryFilter_1 = require("../filters/SignleAttributeCategoryFilter");
var OgAttributes_1 = require("../../../domain/attributes/OgAttributes");
var OgCategories_1 = require("../../../domain/categories/OgCategories");
var ROYALTY_CATEGORY_FILTER = new SignleAttributeCategoryFilter_1.SingleAttributeCategoryFilter(OgCategories_1.OGCategories.ROYALTY, OgAttributes_1.OgAttributes.TYPE, ["Royalty"]);
var ONE_ONE_CATEGORY_FILTER = new SignleAttributeCategoryFilter_1.SingleAttributeCategoryFilter(OgCategories_1.OGCategories.ONE_ONE, OgAttributes_1.OgAttributes.TYPE, ["1-1"]);
var BEDOUIN_ARMY_CATEGORY_FILTER = new SignleAttributeCategoryFilter_1.SingleAttributeCategoryFilter(OgCategories_1.OGCategories.BEDOUIN, OgAttributes_1.OgAttributes.TYPE, ["Bedouin"]);
var ROYAL_ARMY_CATEGORY_FILTER = new SignleAttributeCategoryFilter_1.SingleAttributeCategoryFilter(OgCategories_1.OGCategories.ROYAL_ARMY, OgAttributes_1.OgAttributes.TYPE, ["Royal Army"]);
var ZOMBIE_CATEGORY_FILTER = new SignleAttributeCategoryFilter_1.SingleAttributeCategoryFilter(OgCategories_1.OGCategories.ZOMBIE, OgAttributes_1.OgAttributes.TYPE, ["Zombie"]);
var ANGEL_CATEGORY_FILTER = new SignleAttributeCategoryFilter_1.SingleAttributeCategoryFilter(OgCategories_1.OGCategories.ANGEL, OgAttributes_1.OgAttributes.TYPE, ["Angel"]);
var WISHES_CATEGORY_FILTER = new SignleAttributeCategoryFilter_1.SingleAttributeCategoryFilter(OgCategories_1.OGCategories.WISHES, OgAttributes_1.OgAttributes.TYPE, ["Wishes"]);
var GREY_DUCK_CATEGORY_FILTER = new SignleAttributeCategoryFilter_1.SingleAttributeCategoryFilter(OgCategories_1.OGCategories.GREY_DUCK, OgAttributes_1.OgAttributes.TYPE, ["Grey Duck"]);
var X_RAY_CATEGORY_FILTER = new SignleAttributeCategoryFilter_1.SingleAttributeCategoryFilter(OgCategories_1.OGCategories.X_RAY, OgAttributes_1.OgAttributes.BODY, ["X-Ray"]);
var DESERTER_CATEGORY_FILTER = new SignleAttributeCategoryFilter_1.SingleAttributeCategoryFilter(OgCategories_1.OGCategories.DESERTER, OgAttributes_1.OgAttributes.TYPE, ["Deserter"]);
var ASSEMBLED_CATEGORY_FILTER = new SignleAttributeCategoryFilter_1.SingleAttributeCategoryFilter(OgCategories_1.OGCategories.ASSEMBLED, OgAttributes_1.OgAttributes.TYPE, ["Assembled"]);
var UPPER_CLASS_CATEGORY_FILTER = new SignleAttributeCategoryFilter_1.SingleAttributeCategoryFilter(OgCategories_1.OGCategories.UPPER_CLASS, OgAttributes_1.OgAttributes.TYPE, ["Upper Class"]);
var BACKGROUND_CATEGORY_FILTER = new SignleAttributeCategoryFilter_1.SingleAttributeCategoryFilter(OgCategories_1.OGCategories.BACKGROUND_DUCK, OgAttributes_1.OgAttributes.TYPE, ["Background Duck"]);
var DUCK_CATEGORY_FILTER = new AllCategoryFilter_1.AllCategoryFilter(OgCategories_1.OGCategories.DUCK);
var OG_FILTERS = [
    ROYALTY_CATEGORY_FILTER,
    ONE_ONE_CATEGORY_FILTER,
    BEDOUIN_ARMY_CATEGORY_FILTER,
    ROYAL_ARMY_CATEGORY_FILTER,
    ZOMBIE_CATEGORY_FILTER,
    ANGEL_CATEGORY_FILTER,
    WISHES_CATEGORY_FILTER,
    GREY_DUCK_CATEGORY_FILTER,
    X_RAY_CATEGORY_FILTER,
    DESERTER_CATEGORY_FILTER,
    ASSEMBLED_CATEGORY_FILTER,
    UPPER_CLASS_CATEGORY_FILTER,
    BACKGROUND_CATEGORY_FILTER,
    DUCK_CATEGORY_FILTER,
];
var OGDuckCategoriesService = /** @class */ (function (_super) {
    __extends(OGDuckCategoriesService, _super);
    function OGDuckCategoriesService() {
        return _super.call(this, Collection_1.CollectionName.OG, OG_FILTERS) || this;
    }
    OGDuckCategoriesService.prototype.mapCategoryNameToId = function (category) {
        // This method assumes that OGCategories is an enum and that it's order by id
        return Object.values(OgCategories_1.OGCategories).indexOf(category);
    };
    return OGDuckCategoriesService;
}(CollectionCategoryService_1.CollectionCategoryService));
exports.OGDuckCategoriesService = OGDuckCategoriesService;
