import * as React from "react";
import {FC, useEffect, useState} from "react";

import {Alert, AlertTitle, Snackbar, Slide} from "@mui/material";
import {AlertColor} from "@mui/material/Alert/Alert";
import {TransitionProps} from "@mui/material/transitions";

import "./Notification.scss";
import {NotificationInterface} from "./NotificationInterface";

function SlideTransition(props: TransitionProps) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return <Slide {...props} direction="up"/>;
}

export const Notification: FC<NotificationInterface> = ({alertType, title, message, display, onClose}) => {

  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    setOpen(display);
  }, [display]);

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
    onClose();
  };


  return(
    <Snackbar
      TransitionComponent={SlideTransition} open={open}
      anchorOrigin={{ vertical: "bottom", horizontal:"left" }}
      onClose={handleClose} autoHideDuration={4000}
    >
      <Alert severity={alertType as AlertColor} className={"alert"}>
        { title && <AlertTitle>{title}</AlertTitle> }
        {message}
      </Alert>
    </Snackbar>
  );
};
