"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SingleAttributeCategoryFilter = void 0;
var SingleAttributeCategoryFilter = /** @class */ (function () {
    function SingleAttributeCategoryFilter(category, attributeType, attributeValues) {
        this.category = category;
        this.attributeType = attributeType;
        this.attributeValues = attributeValues;
    }
    SingleAttributeCategoryFilter.prototype.isInCategory = function (collectionItem) {
        var hasAttribute = collectionItem.getAttribute(this.attributeType, this.attributeValues).length > 0;
        // Return undefined, the collection item is not from this category
        if (!hasAttribute)
            return;
        // Return the category
        return this.category;
    };
    return SingleAttributeCategoryFilter;
}());
exports.SingleAttributeCategoryFilter = SingleAttributeCategoryFilter;
