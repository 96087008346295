import {useEffect, useState} from "react";
import {DucksData} from "./DucksData";
import {CollectionName} from "@staking/commons/domain/Collection";
import {StakingApi} from "../apis/StakingApi";
import {CollectionItem} from "@staking/commons/domain/CollectionItem";

export type TokenStatData = {
  collectionItem: CollectionItem;
  reward: number;
}

export type TokenStatInfo = {[key in string]: TokenStatData}

export function useTokenStatsInfo(harvest: boolean, ducksData: DucksData) {
  const [tokenStatInfo, setTokenStatInfo] = useState<TokenStatInfo>({});

  useEffect(() => {
    if(!ducksData) return;

    // If harvest is true then reload without the cache, it means that the user harvested his tokens,
    // and we want to show the updated results
    const cache = !harvest;
    getTokenInfoFromDucksData(
      ducksData,
      cache
    ).then(tokenStatInfo => setTokenStatInfo(tokenStatInfo));

  }, [ducksData, harvest]);

  return {tokenStatInfo};
}

async function getTokenInfoFromDucksData(
  ducksData: DucksData,
  cache = true
): Promise<TokenStatInfo> {
  const tokenStatInfo: TokenStatInfo = {};
  const collections = Object.keys(ducksData);
  const stakingApi = new StakingApi();
  const stackInfoPromises: Promise<CollectionItem[]>[] = [];
  for (let i = 0; i < collections.length; i++) {
    const collection = collections[i];
    const categories = Object.keys(ducksData[collection]);
    for (let j = 0; j < categories.length; j++) {
      const category = categories[j];
      stackInfoPromises.push(
        getDucksStatBatchInfo(
          ducksData[collection][category],
            collection as CollectionName,
            stakingApi,
            cache
        )
      );

    }
  }
  const collectionItems = await Promise.all(stackInfoPromises);
  for (const collectionItemBatch of collectionItems) {
    for (const collectionItem of collectionItemBatch) {
      tokenStatInfo[collectionItem.composedTokenId] = {
        collectionItem,
        reward: collectionItem.reward
      };
    }
  }

  return tokenStatInfo;
}

async function getDucksStatBatchInfo(
  collectionItems: CollectionItem[],
  collectionName: CollectionName,
  stakingApi: StakingApi, 
  cache: boolean
): Promise<CollectionItem[]> {
  const rewards = await stakingApi.getTokenRewards(
    collectionItems.map(collectionItem => collectionItem.blockchainId.toString()),
    collectionName,
    cache
  );

  rewards.forEach((reward, i) => collectionItems[i].reward = reward);
  return collectionItems;
}
